import React from "react";
import styles from "../styles/Insurance.module.css";

function Insurance() {
    // const insurances = [
    //     { name: "Aetna", logo: "/path-to/aetna-logo.png" },
    //     { name: "Cigna", logo: "/path-to/cigna-logo.png" },
    //     { name: "Optum", logo: "/path-to/optum-logo.png" },
    //     { name: "Meritain Health", logo: "/path-to/meritain-logo.png" },
    //     { name: "GHI", logo: "/path-to/ghi-logo.png" },
    //     { name: "Anthem", logo: "/path-to/anthem-logo.png" },
    //     { name: "EmblemHealth", logo: "/path-to/emblemhealth-logo.png" },
    //     { name: "Oxford Health Plans", logo: "/path-to/oxford-logo.png" },
    //     {
    //         name: "United Healthcare",
    //         logo: "/path-to/unitedhealthcare-logo.png",
    //     },
    //     { name: "The Empire Plan", logo: "/path-to/empire-plan-logo.png" },
    // ];

    return (
        // <div className={styles.insuranceContainer}>
        //     <h2>Insurances We Accept</h2>
        //     <div className={styles.insuranceGrid}>
        //         {insurances.map((insurance, index) => (
        //             <div key={index} className={styles.insurance}>
        //                 <img src={insurance.logo} alt={insurance.name} />
        //             </div>
        //         ))}
        //     </div>
        // </div>
        <div id="insurance" className={styles.sectionContainer}>
            <h2>Insurance</h2>
            <div className={styles.sectionContent}>
                <p>
                ABA intervention is recognized the world over as the gold standard for comprehensive programs for individuals with Autism Spectrum Disorders (ASD), and is covered by most private insurance providers and Medicaid. To find out about your child's eligibility, please contact us for more information.

                </p>
            </div>
        </div>
    );
}

export default Insurance;
