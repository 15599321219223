import React from "react";
import "../styles/serviceCard.css"; // Make sure to link the corresponding CSS file

function ServiceCard({ icon, title, description }) {
    return (
        <div className="service-card">
            <img src={icon} alt={`${title} icon`} className="service-icon" />
            <h1>{title}</h1>
            <p>{description}</p>
        </div>
    );
}

export default ServiceCard;
