import React, { useRef } from "react";
import styles from "../styles/Contact.module.css";

const Contact = () => {
    const formRef = useRef();

    const sendEmail = async (e) => {
        e.preventDefault();
        const formData = new FormData(formRef.current);

        try {
            const functionUrl = process.env.NODE_ENV === 'development' 
                ? 'http://localhost:8888/.netlify/functions/send-service-request'
                : '/.netlify/functions/send-service-request';

            // console.log("Sending request to:", functionUrl);

            const response = await fetch(functionUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    parent_first_name: formData.get('parent_first_name'),
                    parent_last_name: formData.get('parent_last_name'),
                    parent_email: formData.get('parent_email'),
                    parent_phone: formData.get('parent_phone'),
                    child_first_name: formData.get('child_first_name'),
                    child_last_name: formData.get('child_last_name'),
                    child_dob: formData.get('child_dob'),
                    name_insurance: formData.get('name_insurance'),
                    insurance_provider: formData.get('insurance_provider'),
                    insurance_id: formData.get('insurance_id'),
                    message: formData.get('message'),
                }),
            });

            // console.log("Response status:", response.status);

            if (!response.ok) {
                const errorText = await response.text();
                // console.error("Error response:", errorText);
                throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
            }

            // const result = await response.json();
            // console.log('Response data:', result);
            alert('Service request submitted successfully!');
            formRef.current.reset();
        } catch (error) {
            // console.error('Error:', error);
            alert(`An error occurred: ${error.message}`);
        }
    };

    return (
        <section id="contact">
            <h2>Request Services</h2>
            <form
                ref={formRef}
                onSubmit={sendEmail}
            >
                {/* Parent Information */}
                <div className={styles.formRow}>
                    <div>
                        <label>Parent First Name</label>
                        <input type="text" name="parent_first_name" required />
                    </div>

                    <div>
                        <label>Parent Last Name</label>
                        <input type="text" name="parent_last_name" required />
                    </div>
                </div>

                <div>
                    <label>Parent Email</label>
                    <input type="email" name="parent_email" required />
                </div>

                <div>
                    <label>Parent Phone Number</label>
                    <input type="tel" name="parent_phone" required />
                </div>

                {/* Child Information */}
                <div className={styles.formRow}>
                    <div>
                        <label>Child First Name</label>
                        <input type="text" name="child_first_name" required />
                    </div>

                    <div>
                        <label>Child Last Name</label>
                        <input type="text" name="child_last_name" required />
                    </div>
                </div>

                <div className={styles.email}>
                    <div>
                        <label>Child Date of Birth</label>
                    </div>
                    <input type="date" name="child_dob" required />
                </div>

                {/* Insurance Information */}
                <div>
                    <label>Name On Insurance</label>
                    <input type="text" name="name_insurance" />
                </div>

                <div>
                    <label>Insurance Provider</label>
                    <input type="text" name="insurance_provider" />
                </div>

                <div>
                    <label>Insurance ID Number</label>
                    <input type="text" name="insurance_id" />
                </div>

                <div>
                    <label>Message</label>
                    <textarea
                        className={styles.message}
                        name="message"
                        rows="3"
                        required
                    ></textarea>
                </div>

                <button type="submit">Submit</button>
            </form>
        </section>
    );
};

export default Contact;
