import styles from "../styles/Home.module.css";
import ServiceCard from "../components/serviceCard";
import abcBlock from "../assets/abc-block.png";
import parentsIcon from "../assets/parentsIcon.png";
import handIcon from "../assets/handsIcon.png";
import socialIcon from "../assets/socialIcon.png";

function Services() {
    return (
        <div className={styles.servicesContainer}>
            <h2 id="services" className={styles.servicesTitle}>
                Our Services
            </h2>
            <div className={styles.serviceSection}>
                <ServiceCard
                    icon={abcBlock}
                    title="One to One Skills and Behavior Interventions"
                    description="Focused interventions to increase Communication and Language skills, Social Communication, Age-Appropriate Play, and Social engagements as per DSM–V guidance, as well as Self-Help Skills for achieving independence. Reduction of Behaviors of concern such as Aggression, Self-Injury, and Tantrums, while reinforcing Positive Behaviors"
                />
                <ServiceCard
                    icon={parentsIcon}
                    title="Parent/Family Training"
                    description="Comprehensive support to help you learn ABA strategies you can use at home and in the community. "
                />
                <ServiceCard
                    icon={handIcon}
                    title="For Professionals"
                    description="Continuing Education workshops coming soon "
                />
                <ServiceCard
                    icon={socialIcon}
                    title="Social Skills Groups"
                    description="For school-aged children who need help making friends and other fulfilling connections."
                />
            </div>
        </div>
    );
}

export default Services;
