import React from "react";
import styles from "../styles/Footer.module.css";
// import kindHearts from "../assets/KIND HEARTS.png";

const Footer = () => {
    return (
        <footer className={styles.footer}>
            <div className={styles.footerTop}>
                <div className={styles.contactInfo}>
                    <a href="mailto:contact@kindheartsaba.com">
                        contact@kindheartsaba.com
                    </a>
                    {/* <span>###-###-####</span> */}
                    {/* <span>address</span> */}
                </div>

                {/* <div className={styles.logo}>
                    <img
                    src={kindHearts}
                    alt="kindHearts"
                /> 
                </div> */}
            </div>

            <div className={styles.footerLinks}>
                <a href="#about">About Us</a>
                <a href="#services">Services We Offer</a>
                <a href="#insurance">Insurances We Accept</a>
                <a href="#contact">Request Services</a>
            </div>

            <div className={styles.footerBottom}>
                <hr />
                <p>Copyright © 2024 Kind Hearts ABA. All rights reserved.</p>
            </div>
        </footer>
    );
};

export default Footer;
