import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import styles from '../styles/Careers.module.css';
import logo from "../assets/new_logo.jpg";
import ReCAPTCHA from "react-google-recaptcha";

function Careers() {
  const formRef = useRef(null);
  const recaptchaRef = useRef(null);
  const [recaptchaToken, setRecaptchaToken] = useState(null);

  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };

  const sendApplication = async (e) => {
    e.preventDefault();
    if (!recaptchaToken) {
      alert('Please complete the reCAPTCHA');
      return;
    }
    const formData = new FormData(formRef.current);

    try {
      const fileInput = formRef.current.querySelector('input[type="file"]');
      const file = fileInput.files[0];

      // Read file as Base64
      const fileContent = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => resolve(event.target.result.split(',')[1]);
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });

      const functionUrl = process.env.NODE_ENV === 'development' 
        ? 'http://localhost:8888/.netlify/functions/send-email'
        : '/.netlify/functions/send-email';

      // console.log('Sending request to:', functionUrl);

      const response = await fetch(functionUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          firstName: formData.get('first_name'),
          lastName: formData.get('last_name'),
          email: formData.get('email'),
          phone: formData.get('phone'),
          address: formData.get('address'),
          city: formData.get('city'),
          state: formData.get('state'),
          postal: formData.get('postal'),
          fileContent: fileContent,
          fileName: file.name,
          recaptchaToken: recaptchaToken,
        }),
      });

      // console.log('Response status:', response.status);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const contentType = response.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        // const result = await response.json();
        // console.log('Response data:', result);
        alert('Application submitted successfully!');
        formRef.current.reset();
      } else {
        // If the response is not JSON, read it as text
        // const text = await response.text();
        // console.log('Response text:', text);
        alert('Application submitted successfully!');
        formRef.current.reset();
      }
    } catch (error) {
      // console.error('Error:', error);
      alert(`An error occurred: ${error.message}`);
    }
  };

  return (
    <div className={styles.careersPage}>
      <div className={styles.header}>
        <Link to="/">
          <img src={logo} alt="Kind Hearts ABA Logo" className={styles.logo} />
        </Link>
        <Link to="/" className={styles.backButton}>Go back to home</Link>
      </div>
      
      <div className={styles.jobHeader}>
        <h2>Behavior Technicians – All Boroughs</h2>
        <div className={styles.jobMeta}>
            <span>$25-30 per hour</span>
            <span>Flexible hours</span>
        </div>
      </div>

      <div className={styles.jobContent}>
        <div className={styles.jobDescription}>
            <p><b>Job Description</b></p>
            <p>We are seeking dedicated and compassionate Behavior Technicians to join our team in New York City. As a Behavior Technician, you will work directly with children and families to implement evidence-based behavioral interventions. This role requires a strong commitment to providing high-quality care and support to individuals with autism spectrum disorder (ASD).</p>
          <p><b>Qualifications:</b></p>
          <ul>
            <li>Must have a High School Diploma</li>
            <li>Have access to reliable transportation</li>
            <li>Experience working with children with ASD preferred</li>
            <li>Strong communication skills</li>
            <li>Ability to work with confidential client information</li>
          </ul>

          <p><b>Responsibilities:</b></p>
          <p>Your responsibilities as a Behavior Technician at Kind Hearts will include the following:</p>
          <ul>
            <li>Implement 1:1 behavioral treatment plans designed by Board Certified Behavior Analyst BCBA/NYS and any other tasks outlined by the BCBA/LBA in the client's home or community location</li>
            <li>Data collection via software to monitor the progress and effectiveness of the individualized treatment plan and report progress</li>
            <li>Support the BCBA with training families on implementing the individualized treatment plans</li>
            <li>Accommodate the needs and requests of clients, their families, and supervisors</li>
            <li>Utilize communication skills to provide effective feedback to clients and families</li>
            <li>Maintain continuous communication with families, supervisors, and other applicable parties</li>
            <li>Physically keep up with clients as they move about the session area(s). This will require many physical activities including, but not limited to, standing, sitting, bending, lifting 25+ pounds, running, squatting, kneeling, and generally keeping pace with children from 2-21 years old</li>
          </ul>
        </div>

        <div className={styles.applicationForm}>
          <h1>Apply for this position</h1>
          <p className={styles.required}>REQUIRED *</p>
          <form encType="multipart/form-data"
                method="post" 
                ref={formRef} 
                onSubmit={sendApplication}>
            <div>
              <label htmlFor="first_name">First Name *</label>
              <input type="text" name="first_name" id="first_name" required />
            </div>

            <div>
              <label htmlFor="last_name">Last Name *</label>
              <input type="text" name="last_name" id="last_name" required />
            </div>

            <div>
              <label htmlFor="email">Email Address *</label>
              <input type="email" name="email" id="email" required />
            </div>

            <div>
              <label htmlFor="phone">Phone *</label>
              <input type="tel" name="phone" id="phone" required />
            </div>

            <div>
              <label htmlFor="address">Address</label>
              <input type="text" name="address" id="address" placeholder="Address" />
            </div>

            <div className={styles.formRow}>
            <label htmlFor="address">City</label>
              <input type="text" name="city" id="city" placeholder="City" />
              <label htmlFor="address">State</label>
              <input type="text" name="state" id="state" placeholder="State" />
              <label htmlFor="address">Postal Code</label>
              <input type="text" name="postal" id="postal" placeholder="Postal Code" />
            </div>

            <div>
              <label htmlFor="resume">Resume * (PDF files only, must be less than 40MB)</label>
              <input type="file" name="resume" id="resume" accept=".pdf"  required/> 
            </div>

            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onChange={handleRecaptchaChange}
            />
            <button type="submit" className={styles.submitButton}>Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Careers;
