import styles from "../styles/About.module.css";

function About() {
    return (
        <div className={styles.background}>
            <div className={styles.coFoundersContainer}>
                <h2 className={styles.aboutTitle}>Our Co-Founders</h2>
                <div className={styles.founders}>
                    <div className={styles.founder}>
                        <p>
                            Kind Hearts ABA was founded by compassionate
                            BCBAs/NYS LBAs who are also NYS Certified Classroom
                            Special Education Teachers from Birth-12th grade.
                            Their education includes Masters in
                            Organizational/Industrial Psychology, Masters in
                            General Education and Special Education, and
                            Advanced Graduate Certificates in Applied Behavior
                            Analysis. They have nearly 20 years of experience in
                            working with children with disabilities both 1:1 and
                            in the classroom. They pride themselves in working
                            with families to help them help themselves and their
                            children attain a higher quality of life. <br></br><br></br>Our kind
                            hearted and compassionate team speaks Russian,
                            Ukrainian, Georgian, Serbo-Croatian, Arabic,
                            Turkish, Urdu, Spanish, Yiddish, and Hebrew.
                        </p>
                    </div>
                    {/* <div className={styles.founder}>
                        <h3>Milena Priven</h3>
                        <p>BCBA</p>
                        <p>
                            Milena has a master's degree in special education
                            and is a licensed Board Certified Behavior Analyst
                            with over 15 years of experience in the field of
                            education. She has worked as a supervisor of ABA
                            services in school settings and as an inclusion
                            specialist - responsible for training therapists,
                            teachers, and parents and helping them design
                            specific curriculums to meet their student's and
                            children's individual needs in the classroom and at
                            home.
                        </p>
                    </div> */}
                </div>
            </div>
        </div>
    );
}

export default About;
