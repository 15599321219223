import React, { useState } from "react";
import { Link } from "react-scroll";
import "../styles/Nav.css";
import logo from "../assets/new_logo.jpg";
// Make sure you're using react-router for page navigation
import { Link as RouterLink } from 'react-router-dom';

function Nav() {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const closeMenu = () => {
        setIsOpen(false);
    };

    return (
        <nav className="navbar">
            <Link to="/">
                {/* Insert your logo image here */}
                <img className="logo" src={logo} alt="Proud Moments ABA" />
            </Link>

            <div className={`nav-links ${isOpen ? "show" : ""}`}>
                <Link onClick={closeMenu} to="about" smooth={true} duration={500}>
                    About Us
                </Link>
                <Link onClick={closeMenu} to="services" smooth={true} duration={500}>
                    Services
                </Link>
                <Link onClick={closeMenu} to="insurance" smooth={true} duration={500}>
                    Insurance
                </Link>
                <Link onClick={closeMenu} to="contact" smooth={true} duration={500}>
                    Contact
                </Link>
                <RouterLink to="/careers" onClick={closeMenu}>
                    Careers
                </RouterLink>
            </div>

            <div className="nav-get-started">
                <Link
                    to="contact"
                    smooth={true}
                    duration={500}
                    className="get-started-button"
                >
                    Request Service
                </Link>
            </div>

            <button className={`nav-toggle ${isOpen ? "open" : ""}`} onClick={toggleMenu}>
                <span className="hamburger"></span>
            </button>
        </nav>
    );
}

export default Nav;
