import React from "react";
import { Link } from "react-scroll";
import styles from "../styles/Home.module.css";
import child2 from "../assets/child2-home.jpg";
import child3 from "../assets/child3-home.jpg";
import child4 from "../assets/child4-home.jpg";
import Footer from "../components/footer";
import Services from "../components/Services";
import About from "../components/about";
import Insurance from "../components/insurance";
import Contact from "../components/contact";


function Home() {
    return (
        <div className={styles.background}>
            <div className={styles.heroSection}>
                {/* <img
                    src={logo}
                    alt="kindHearts"
                    className={styles.heroImage}
                /> */}
                <div className={styles.heroText}>
                    <h1>Transforming Challenges into Achievements</h1>
                    <p>
                        Help your your child shine. Start this exciting journey.
                        Take the first step with us today!
                    </p>
                    <div>
                        <Link
                            className={styles.startIntakeButton}
                            to="about"
                            smooth={true}
                            duration={500}
                        >
                            Get Started!
                        </Link>
                    </div>
                </div>
            </div>

            <div className={styles.sectionContainer}>
                <h2>How ABA Therapy Can Help Your Child</h2>
                <div className={styles.sectionContent}>
                    <p>
                    At the heart of Applied Behavior Analysis (ABA) therapy, we prioritize compassion to create positive changes for individuals with autism. As we already use ABA in everyday life, at Kind Hearts ABA, the principles of ABA are tailored to help children overcome any challenge.  Our goal is to help children learn new skills and decrease behaviors of concern that might interfere with learning and socializing. We help each child thrive while using compassionate strategies.
                    </p>
                    <p>
                    We’re on a mission to bring the very best of ABA therapy to each child we meet. With a plan made just for them, we are here to unlock the world of self-expression and independence. Through structured learning, social skills, and compassion, we are here to guide families with a kind heart and compassionate approach.

                    </p>
                </div>
            </div>
            <div className={styles.therapyPlansContainer}>
                <h2 id="about" className={styles.uniqueTitle}>
                    What Sets Us Apart
                </h2>
                <div className={styles.therapyPlansSection}>
                    <div className={styles.blob2}>
                        <div className={styles.textContent}>
                            <h2>INDIVIDUALIZED THERAPY PLANS</h2>
                            <p>
                            At Kind Hearts Applied Behavior Analysis we understand that every child is unique. We approach every child with a kind heart, compassion, and positivity.  We tailor therapy to the specific needs of your child and family, using the evidence-based science of Applied Behavior Analysis (ABA). ABA therapy is an approach that utilizes interventions based on principles of learning and positive reinforcement. ABA strategies enhance skill levels in many areas, including but not limited to being able to follow simple instructions, communication, social interactions and relationships, age-appropriate play, and self-help skills.  ABA strategies are also used to help reduce behaviors of concern such as aggression, self-injurious behaviors, tantrums, and self-stimulatory behaviors that interfere with safety or learning.
                            </p>
                        </div>
                    </div>
                    <div className={styles.imageContent}>
                        <img
                            src={child2}
                            alt="Happy Child"
                            className={styles.childImage}
                        />
                    </div>
                </div>
                <div className={styles.guidanceSupportContainer}>
                    <div className={styles.imageContent}>
                        <img
                            src={child3}
                            alt="Parent and child"
                            className={styles.childImage}
                        />
                    </div>
                    <div className={styles.blob4}>
                        <div className={styles.textContainer}>
                            <h2>CONTINUED GUIDANCE & SUPPORT</h2>
                            <p>
                            Parents and families benefit from our services as well. Our Parent Training sessions help families carry over techniques that are used during their child’s direct ABA sessions. The goal is to empower you with comprehensive techniques that help to improve interactions with your child. Practice and repetition at home can help your child improve on their behavioral and skill acquisition goals even further, leading to an overall happier family life at home and when you’re out and about in the community. With the consent of the parent/guardian, we also include siblings, grandparents, and other caregivers during family training sessions.  Our goal is to create the best quality of life for your child and family.
                            </p>
                        </div>
                    </div>
                </div>

                <div className={styles.therapyPlansSection}>
                    <div className={styles.blob3}>
                        <div className={styles.textContent}>
                            <h2>A UNIQUE & PERSONAL APPROACH</h2>
                            <p>
                            You are your child’s expert and know your child better than anyone else. So our first plan of action is attending to you. Once we get a better idea from you of the challenges at hand, we conduct a skills assessment and our findings are used to create a personalized treatment plan for your child. The plan is then led and monitored by a Board Certified Behavior Analyst (BCBA)/NYS Licensed Behavior Analyst (LBA). Each child receives 1:1 interventions as per their treatment plan.  All services leverage our extensive, BCBA-designed treatment plans that incorporate compassionate, fun, effective, and evidence-based practices. We collect extensive data during each therapy session to observe and analyze your child’s progress.
                            </p>
                        </div>
                    </div>
                    <div className={styles.imageContent}>
                        <img
                            src={child4}
                            alt="Happy Child"
                            className={styles.childImage}
                        />
                    </div>
                </div>
                {/* <div>
                <Link className={styles.startIntakeButton} to="about" smooth={true} duration={500}>Contact Us</Link>
                </div> */}
            </div>

            <About />
            <Services />
            <Insurance />
            <Contact />

            <Footer />
        </div>
    );
}

export default Home;
